@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.coloreSfumato{
	background: rgba(0, 0, 0, 0) linear-gradient(to right, rgb(95,196,225) 0%, rgb(0,157,204) 0%, rgb(0,116,166) 100%) repeat scroll 0 0;
}

body {
    margin: 0px;
    padding: 0px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

 .prova .mat-form-field-label{
	color: #ffffff !important;
	font-size: 1.5em!important;
}

// .mat-form-field-label{
// 	background-color: rgb(0,116,166) !important;
// }

.divInRow{
	flex-direction: column;
}

 .underline .mat-form-field-underline {
	height: 0 !important;
}

.height-100vh {
	height: 92vh;
}

.width-100 {
	width: 100%;
}

.width-40 {
	width: 40%;
}

.centered {
	display: flex !important;
	align-items: center;
	justify-content: center;
	
}
.bg-celeste{
	position: absolute;
	width: 100vw;
	background:white;
}
.bg-celeste:after{
	background-image: url("assets/icon/weople_pdg.png");
	background-repeat: no-repeat;
	background-position: bottom;
}

.divPasDimenticata{
	text-align: -webkit-center ;
	color: #ffffff;
}

.flex-row {
	display: flex;
    flex-direction: row;
}

.space-evenly {
//	align-items: center;
    justify-content: space-evenly;
}

.space-between {
	align-items: center;
	justify-content: space-between;
}

.failure {
	background-color: red;
}

.during-loading {
	height: 100vh;
	overflow: hidden;
}

.navbar-logo-container{ flex-grow: 3; 
    padding-top: 0.5em;
	img{height:4vh;}
	
}


.column {
    flex-direction: column;
}

